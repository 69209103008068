.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 1 1s ease-out;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.App-header {
  background-color: #212723;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  font-family: 'Verdana';
  color: #ffffff;
  margin: 0em;
  padding: 1em;
}

.App-link {
  color: #61dafb;
}

.Body {
  background-color: #212723;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SeparatorSmall {
  background-color: #212723;
  min-height: 2.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SeparatorLarge {
  background-color: #212723;
  min-height: 7.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.Title {
  font-size: calc(28px + 2vmin);
  font-family: 'Lora';
  color: #ffffff;
}

.Hero1 {
  color: rgb(176, 255, 130);
  font-size: calc(12px + 1.8vmin);
  font-family: 'Verdana';
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.Hero2 {
  color: rgb(80, 226, 252);
  font-size: calc(12px + 1.6vmin);
  font-family: 'Verdana';
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.HeroDefault {
  color: #ffffff;
  font-size: calc(12px + 1.6vmin);
  font-family: 'Verdana';
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.e1 {
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.e2 {
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.e3 {
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 3.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.e4 {
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 7.2s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.BodyText1 {
  color: white;
  font-size: calc(12px + 1.5vmin);
  font-family: 'Verdana';
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 4.8s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

.BodyText2 {
  color: white;
  font-size: calc(12px + 1.5vmin);
  font-family: 'Verdana';
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 5.7s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

// ROI Graphic Div

.ROIDiv {
  background-color: #212723;
  width: 100%;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: center;
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.GraphicDiv1 {
  background-color: #edfaf9;
  border-radius: clamp(30px, 1vw, 50px);
  width: clamp(180px, 45vw, 450px);
  flex: none;
  margin-right: 2vw;
  margin-left: 5vw;
  align-self: center;
}
.DescriptionDiv1 {
  color: white;
  background-color: #212723;
  width: clamp(200px, 50vw, 500px);
  flex: none;
  margin-right: 5%;
  margin-left: 2%;
}
.DescriptionHeader1 {
  color: white;
  font-size: calc(12px + 1.3vmin);
  font-family: 'Verdana';
  text-align: left;
}
.DescriptionText1 {
  color: white;
  font-size: calc(10px + 1.1vmin);
  font-family: 'Verdana';
  line-height: 1.4;
  text-align: left;
}

// Time Series Graph

.GraphDiv {
  background-color: #212723;
  width: 100%;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: center;
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.GraphicDiv2 {
  background-color: #edfaf9;
  border-radius: clamp(30px, 1vw, 50px);
  width: clamp(180px, 45vw, 450px);
  flex: none;
  margin-right: 5%;
  margin-left: 2%;
  align-self: center;
}
.DescriptionDiv2 {
  color: white;
  background-color: #212723;
  width: clamp(200px, 50vw, 500px);
  flex: none;
  margin-right: 2%;
  margin-left: 5%;
}
.DescriptionHeader2 {
  color: white;
  font-size: calc(12px + 1.3vmin);
  font-family: 'Verdana';
  text-align: right;
}
.DescriptionText2 {
  color: white;
  font-size: calc(10px + 1.1vmin);
  font-family: 'Verdana';
  line-height: 1.4;
  text-align: right;
}

// ARTIMIS Image

.ImageDiv {
  background-color: #212723;
  width: 100%;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: center;
  opacity: 0;
  filter: blur(4);
  animation: fade-in 0.8s 3.5s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.GraphicDiv3 {
  flex: none;
  margin-right: 2%;
  margin-left: 5%;
  width: clamp(180px, 45vw, 450px);
  align-self: center;
}
.Image {
  display: block;
  width: 100%;
  border-radius: clamp(30px, 1vw, 50px);
}
.DescriptionDiv3 {
  color: white;
  background-color: #212723;
  width: clamp(200px, 50vw, 500px);
  flex: none;
  margin-right: 5%;
  margin-left: 2%;
}
.DescriptionHeader3 {
  color: white;
  font-size: calc(12px + 1.3vmin);
  font-family: 'Verdana';
  text-align: left;
}
.DescriptionText3 {
  color: white;
  font-size: calc(10px + 1.1vmin);
  font-family: 'Verdana';
  line-height: 1.4;
  text-align: left;
}

// Form

.FormDiv {
  background-color: #212723;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //-ms-transform: translateX(-7%);
  //transform: translateX(-7%);
}

.FormLabel {
  font-size: calc(12px + 1.2vmin);
  font-family: 'Verdana';
  display: flex;
  color: #ffffff;
  padding: 8px 8px 1px 1px;
  margin: 8px 8px;
  opacity: 0;
  animation: fade-in 0.8s 5.8s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.FormInput[type=text] {  
  display: flex;
  width: 100%;
  padding: 8px 8px;
  margin: 0px 8px;
  border: 2px solid white;
  border-radius: 4px;
  color: black;
  opacity: 0;
  animation: fade-in 0.8s 5.8s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.FormInput[type=email] {  
  display: flex;
  width: 100%;
  padding: 8px 8px;
  margin: 0px 8px;
  border: 2px solid white;
  border-radius: 4px;
  color: black;
  opacity: 0;
  animation: fade-in 0.8s 5.8s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.FormInput[type=text]:focus {  
  border: 2px solid #6695d3;
}
.FormInput[type=email]:focus {  
  border: 2px solid #6695d3;
}

.FormButton {
  background-color: #0094D9;
  color: #ffffff;
  display: block;
  width: 120px;
  height: 45px;
  font-family: 'Segoe UI';
  font-size: calc(12px + 1.1vmin);
  border-radius: 8px;
  box-shadow: inset 0 0 5px #31C5FF;
  border: 0px solid black;
  margin: 8px 8px;
  align-self: center;
  opacity: 0;
  animation: fade-in 0.8s 5.7s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.FormButton:hover {
  background-color: #00B7FF;
}
.FormButton:focus {
  background-color: rgb(176, 255, 130);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.columnCenter {
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #212723;
  min-height: 20vh;
}


@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}